import { ApolloClient, InMemoryCache } from '@apollo/client'

const accessToken =
  process.env.ENV === 'production'
    ? process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
    : process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN

const environment = process.env.ENV === 'production' ? 'master' : 'dev'

const client = new ApolloClient({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/${environment}?access_token=${accessToken}`,
  cache: new InMemoryCache()
})

export default client
