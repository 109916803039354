import tw, { css, theme } from 'twin.macro'

const styles = ({ padded }) => css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  margin-top: ${padded ? '7.25rem' : '0'};

  @media (min-width: ${theme`screens.md`}) {
    margin-top: ${padded ? '10rem' : '0'};
  }

  .container {
    flex: 1 1 auto;
  }
`

export default styles
