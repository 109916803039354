const SUPPORTED_NFT_IDS = {
  // autumnal
  '86523522492049829069443461531283592924236790851100186373218193581263451951808': 1,
  // winter
  '38878726256763679420943540510531423191402572966040947874232958312086916523974': 2,
  // vernal
  '109052468248288628902262204710929342315603261628296284007539858800284873435807': 3,
  //summer
  '50819302704671850044952851024417088541934493773578185659356113463834968215023': 4
}

const NFT_IDS_BY_NAME = {
  // autumnal
  autumnal:
    '86523522492049829069443461531283592924236790851100186373218193581263451951808',
  // winter
  winter:
    '38878726256763679420943540510531423191402572966040947874232958312086916523974',
  // vernal
  vernal:
    '109052468248288628902262204710929342315603261628296284007539858800284873435807',
  // summer
  summer:
    '50819302704671850044952851024417088541934493773578185659356113463834968215023'
}

const NFT_URLS = {
  // autumnal
  autumnal: `https://marketplace.equinox.fund/item/0xbf4a940a1107e51f026bdd5a53a39b9c925a029aa0bc81f6bff64b2c6f8192c0`,
  // winter
  winter: `https://marketplace.equinox.fund/item/0x55f494499ff3256e7ab4a21ba7d57918fcba976fff1d91e92125a992187f67c6`,
  // vernal
  vernal: `https://marketplace.equinox.fund/item/0xf119827bbdf1af8b420dd71376ae288470d5d5d2c2d205ce223ce30b7aceaa9f`,
  // summer
  summer: `https://marketplace.equinox.fund/item/0x705ab4bda87c5851a0976387b3029e8f444ca1695f0ca934e888f9a500124def`
}

export { SUPPORTED_NFT_IDS, NFT_URLS, NFT_IDS_BY_NAME }
