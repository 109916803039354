/**
 * Get a nested value from an object without tears
 * for example : getObjectProperty(object, "property.nested.value")
 * But also for arrays : getObjectProperty(object, "property.array[0].value")
 */

import { isDefined, isNotEmptyObject } from 'class-validator'

const getObjectProperty = (
  obj: { [index: string]: any } | null,
  path: string,
  fallback: any = null
): any => {
  if (!isNotEmptyObject(obj)) return fallback

  let cloneObj = { ...obj }

  function everyFunc(step) {
    if (!isDefined(cloneObj[step])) return false

    cloneObj = cloneObj[step]
    return true
  }

  const fullPath = path.replace(/\[/g, '.').replace(/]/g, '').split('.')

  return fullPath && fullPath.every(everyFunc) ? cloneObj : fallback
}

export default getObjectProperty
