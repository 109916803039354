import ConnectedWallet from '@components/common/ConnectedWallet'
import SocialLinks from '@components/layout/Footer/SocialLinks'
import ConnectWalletButton from '@components/layout/Header/ConnectWalletButton'
import { Title } from '@jeromevvb/equinox-lib'
import useWallet from '@contexts/WalletContext/hooks/useWallet'
import Link from 'next/link'
import ModalContainer from 'react-overlays/Modal'
import styles from './styles'

interface MobileMenuProps {
  show: boolean
  setShow: (e: any) => void
  logout: () => void
}

const MobileMenu = ({ show, setShow }: MobileMenuProps) => {
  const wallet = useWallet()

  return (
    <ModalContainer css={styles({ show })} show={show}>
      <>
        <nav>
          {wallet.mainAccount && <ConnectedWallet />}
          {!wallet.mainAccount && (
            <ConnectWalletButton className="mx-auto mb-10" />
          )}

          <div onClick={() => setShow(false)}>
            <Link href="https://marketplace.equinox.fund">
              <Title size="medium" className="nav-link">
                Marketplace
              </Title>
            </Link>
            <Link href="https://guild.equinox.fund">
              <Title size="medium" className="nav-link">
                Guild
              </Title>
            </Link>
            <Link href="/faq">
              <Title size="medium" className="nav-link">
                FAQ
              </Title>
            </Link>
          </div>
        </nav>
        <nav className="menu-socials">
          <SocialLinks className="justify-center" />
        </nav>
      </>
    </ModalContainer>
  )
}

export default MobileMenu
