import tw, { css } from 'twin.macro'

const styles = ({ visible }) => css`
  ${tw`bg-primary`}
  transform: translateY(${visible ? '0%' : '-100%'} );
  ${tw`transition-all`}
  ${tw`duration-slow`}

  .banner-inner {
    ${tw`py-2`}
    ${tw`mb-0`}
    ${tw`xs:flex`}
    ${tw`xs:flex-nowrap`}
    ${tw`xs:justify-between`}
    ${tw`xs:items-center`}
  }

  .text {
    ${tw`mb-4`}
    ${tw`xs:mb-0`}
    ${tw`xs:mr-4`}
  }

  align-items,
  button {
    ${tw`w-full`}
    ${tw`xs:w-auto`}
  }
`

export default styles
