import addStatusToProject from '@helpers/addStatusToProject'
import sortProjectsByStatus from '@helpers/sortProjectsByStatus'
import Status from '@models/projects/enums/status.enum'
import Project from '@models/projects/interfaces/project.interface'
import { createContext, useMemo, useState } from 'react'

interface IFetchedProjects {
  upcoming: Project[]
  ended: Project[]
  current?: Project
  featured?: Project
}

interface IContext {
  projects: {
    [Status.ACTIVE]: Project[]
    [Status.UPCOMING]: Project[]
    [Status.ENDED]: Project[]
  }
  currentProject: Project | null
  featuredProject: Project | null
  setFetchedProjects(projects: IFetchedProjects): void
  refreshProjects(): void
  relatedProjects(slug: string): {
    next: Project
    previous: Project
    nextToLaunch: Project
  }
}

export const ProjectsContext = createContext<Partial<IContext>>({})

const ProjectsContextProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState(null)
  const [featuredProject, setFeaturedProject] = useState(null)
  const [projects, setProjects] = useState({
    [Status.ACTIVE]: [],
    [Status.UPCOMING]: [],
    [Status.ENDED]: []
  })

  const allProjects = useMemo(
    () => [...projects.active, ...projects.upcoming, ...projects.ended],
    [projects.active, projects.upcoming, projects.ended]
  )

  const setFetchedProjects = (props: IFetchedProjects) => {
    const { active, upcoming } = sortProjectsByStatus(
      props.upcoming as Project[]
    )
    if (props.featured) {
      setFeaturedProject(addStatusToProject(props.featured))

      // If featured project exists, remove that project
      // from active or upcoming projects
      const matchSlug = ({ slug }) => slug === props.featured.slug
      let duplicateIndex = upcoming.findIndex(matchSlug)

      if (duplicateIndex !== -1) {
        upcoming.splice(duplicateIndex, 1)
      } else {
        duplicateIndex = active.findIndex(matchSlug)
        active.splice(duplicateIndex, 1)
      }
    }

    setProjects({ active, upcoming, ended: props.ended })

    if (props.current) {
      setCurrentProject(addStatusToProject(props.current))
    }
  }

  const refreshProjects = () => {
    const { active, upcoming } = sortProjectsByStatus(allProjects as Project[])
    setProjects((projects) => ({ ...projects, active, upcoming }))
    setCurrentProject(addStatusToProject(currentProject))
  }

  const relatedProjects = (slug: string) => {
    const currentProjectIndex = allProjects.findIndex(
      (project) => project.slug === slug
    )
    const next = allProjects[currentProjectIndex - 1]
    const previous = allProjects[currentProjectIndex + 1]
    const nextToLaunch = projects.upcoming[0]
    return { next, previous, nextToLaunch }
  }

  const value = {
    projects,
    currentProject,
    featuredProject,
    setFetchedProjects,
    refreshProjects,
    relatedProjects
  }

  return (
    //@ts-ignore
    <ProjectsContext.Provider value={value}>
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsContextProvider
