import useUser from '@contexts/UserContext/hooks/useUser'
import {
  Banner as BannerLib,
  Button,
  Modal,
  ModalFooter,
  TextField,
  Typography
} from '@jeromevvb/equinox-lib'
import { ChangeEvent, useState } from 'react'
import Banner from '../Banner'

const validateEmail = (email) => {
  const regex = /\S+@\S+\.\S+/
  return regex.test(email)
}

const EmailVerifyBanner: React.FC = () => {
  const { verifyEmail } = useUser()
  const [emailModal, setEmailModal] = useState(false)
  const [email, setEmail] = useState(null)

  const handleChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  return (
    <>
      <Modal
        show={emailModal}
        setShow={setEmailModal}
        title="Verify your email"
      >
        {verifyEmail.isSuccess && (
          <Typography>
            We've sent an email to verify your email address. Please make sure
            to check your Junk mailbox.
          </Typography>
        )}
        {!verifyEmail.isSuccess && (
          <>
            <BannerLib>
              <div className="flex flex-col">
                <Typography weight="semibold">Please note</Typography>
                <Typography caption>
                  If you already own an Equinox account, make sure to use the
                  same email address as registered before.
                </Typography>
              </div>
            </BannerLib>

            <div className="mt-8">
              <TextField
                label="Your email address"
                onChange={handleChangeTextField}
              />
            </div>

            {verifyEmail.isError && (
              <div className="mt-8">
                <Typography color="error">
                  We had an issue verifying your email.
                  <br />
                  {verifyEmail.error.response?.data?.message || 'Network error'}
                </Typography>
              </div>
            )}

            <ModalFooter
              buttonPrimary={
                <Button
                  variant="contained"
                  loading={verifyEmail.isLoading}
                  disabled={!validateEmail(email)}
                  onClick={() => verifyEmail.mutate({ email })}
                >
                  Verify email
                </Button>
              }
            />
          </>
        )}
      </Modal>
      <Banner
        text="Please verify your email to participate in Equinox IDOs/INOs"
        cta={
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => setEmailModal(true)}
          >
            Verify email
          </Button>
        }
      />
    </>
  )
}

export default EmailVerifyBanner
