import Status from '@models/projects/enums/status.enum'
import Project from '@models/projects/interfaces/project.interface'

import dayjs from 'dayjs'

/**
 * Add status to project
 * @returns
 */
const addStatusToProject = (project: Project): Project => {
  const { poolOpenDate } = project

  if (!poolOpenDate) {
    return { ...project, status: Status.UPCOMING }
  }

  const diff = dayjs(poolOpenDate).diff(dayjs(), 'second')

  let stateKey
  // if project is ended
  if (project.ended === true) {
    stateKey = Status.ENDED
  } else {
    // in the future
    // PROJECT IS UPCOMING
    if (diff > 0) {
      stateKey = Status.UPCOMING

      // PROJECT IS ACTIVE
      // NOTE: will be active until ended is set to true.
    } else {
      stateKey = Status.ACTIVE
    }
  }

  return { ...project, status: stateKey }
}

export default addStatusToProject
