import { WEBSOCKETS } from '@config/rpc'
import ChainId from '@models/enums/chainId.enums'
import Env from '@models/enums/env.enum'
import Network from '@models/projects/enums/network.enum'
import ProjectNetwork from '@models/projects/enums/projectNetwork.enum'
import ChainWebSockets from '@services/chainWebsockets'
import { ethers } from 'ethers'

const websockets = new ChainWebSockets()

/**
 * Get chain id from network.
 * @returns
 */
const getChainIdFromNetwork = (network: Network | ProjectNetwork): ChainId => {
  const chainIds = {
    [Env.PRODUCTION]: {
      [Network.ETH]: ChainId.MAINNET,
      [Network.BSC]: ChainId.BSC,
      [Network.POLYGON]: ChainId.POLYGON
    },
    [Env.PREVIEW]: {
      [Network.ETH]: ChainId.RINKEBY,
      [Network.BSC]: ChainId.BSC_TESTNET,
      [Network.POLYGON]: ChainId.POLYGON_TESTNET
    },
    [Env.DEV]: {
      [Network.ETH]: ChainId.RINKEBY,
      [Network.BSC]: ChainId.BSC_TESTNET,
      [Network.POLYGON]: ChainId.POLYGON_TESTNET
    }
  }

  return chainIds[process.env.ENV][network]
}

/**
 *  Get chain label from network name.
 * @param chainId
 * @returns  string
 */
const getChainLabelFromNetwork = (
  network: Network | ProjectNetwork
): string => {
  const labels = {
    [Env.PRODUCTION]: {
      [Network.ETH]: 'Mainnet',
      [Network.BSC]: 'Binance Smart Chain',
      [Network.POLYGON]: 'Polygon'
    },
    [Env.PREVIEW]: {
      [Network.ETH]: 'Rinkeby',
      [Network.BSC]: 'Binance Smart Chain Testnet',
      [Network.POLYGON]: 'Polygon Testnet'
    },
    [Env.DEV]: {
      [Network.ETH]: 'Rinkeby',
      [Network.BSC]: 'Binance Smart Chain Testnet',
      [Network.POLYGON]: 'Polygon Testnet'
    }
  }

  return labels[process.env.ENV][network] || 'Unknown'
}

/**
 * Get network from chainId
 * @param chainId
 * @returns
 */
const getNetworkFromChainId = (chainId: ChainId): Network => {
  if (!chainId) return null

  if ([ChainId.BSC, ChainId.BSC_TESTNET].includes(chainId)) {
    return Network.BSC
  }

  if ([ChainId.POLYGON, ChainId.POLYGON_TESTNET].includes(chainId)) {
    return Network.POLYGON
  }

  return Network.ETH
}

/**
 * getProviderForChain
 * Get the websocket provider for a given chain (network)
 * @param network
 * @returns
 */
const getProviderForChain = (
  network: Network | ProjectNetwork
): ethers.providers.WebSocketProvider => {
  const requestedChainId = getChainIdFromNetwork(network)

  // if (requestedChainId === ChainId.BSC) {
  //   return new ethers.providers.JsonRpcProvider(
  //     'https://bsc-dataseed.binance.org/'
  //   )
  // }

  // if the connection is open
  const connection = websockets.getConnection(requestedChainId)

  if (connection) return connection

  // get endpoint
  const endpoint = WEBSOCKETS[requestedChainId]

  const websocket = new ethers.providers.WebSocketProvider(
    endpoint,
    requestedChainId
  )

  // save connection.
  websockets.addConnection(requestedChainId, websocket)
  return websocket
}

export {
  getChainIdFromNetwork,
  getChainLabelFromNetwork,
  getNetworkFromChainId,
  getProviderForChain
}
