type Variables = {
  [name: string]: string
}

/**
 * This function replaces given variables into a string
 * @param str {string}
 * @param variables {object}
 * @returns
 */
const smartReplace = (str: string, variables?: Variables) => {
  if (!variables || !str) return str

  let clonedStr = str.slice()

  for (const key of Object.keys(variables)) {
    clonedStr = clonedStr.replace(`{${key}}`, variables[key])
  }

  return clonedStr
}

export default smartReplace
