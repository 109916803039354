/**
 * RPC regarding chainID
 * 1 : mainnet
 * 4: rinkeby
 * 56: Binance Smart Chain
 * 97: Binance Smart Chain TestNet
 * 137: polygon
 * 80001: polygon testnet
 */
const JSONRPC = {
  1: 'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/eth/mainnet',
  4: 'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/eth/rinkeby',
  56: 'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/bsc/mainnet',
  97: 'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/bsc/testnet',
  137: 'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/polygon/mainnet',
  80001:
    'https://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/polygon/mumbai'
}

const WEBSOCKETS = {
  1: 'wss://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/eth/mainnet/ws',
  4: 'wss://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/eth/rinkeby/ws',
  56: 'wss://proud-misty-brook.bsc.quiknode.pro/2062fb5c7687cfe7857ed2e7ccbea00e59ea460a/',
  97: 'wss://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/bsc/testnet/ws',
  137: 'wss://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/polygon/mainnet/ws',
  80001:
    'wss://speedy-nodes-nyc.moralis.io/b36d19be30acc6524f7684b7/polygon/mumbai/ws'
}

export { JSONRPC, WEBSOCKETS }
