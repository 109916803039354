import api from '@config/api'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { createContext, ReactNode, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMoralis } from 'react-moralis'
import { useMutation, useQuery } from 'react-query'
import useJwt from './hooks/useJwt'
import { TUser, TUserContext, TSaveWalletDto, TVerifyEmailDto } from './types'

export const UserContext = createContext<TUserContext>({
  user: null,
  isAuthenticated: false,
  Authorization: null,
  email: null,
  isEmailVerified: false,
  saveExternalWallet: null,
  verifyEmail: null,
  destroyUser: null
})

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { destroy, jwtToken } = useJwt()
  const [user, setUser] = useState<TUser>(null)
  const { user: moralisUser, isAuthenticated, refetchUserData } = useMoralis()

  const moralisAccessToken = moralisUser?.attributes?.accessToken || null

  /**
   * Destroy user
   */

  const destroyUser = () => {
    destroy()
    setUser(null)
  }

  /**
   * refetch moralis user when page loaded
   */

  useEffect(() => {
    if (!isAuthenticated) return
    refetchUserData()
  }, [isAuthenticated, refetchUserData])

  /**
   *
   * Get user
   *
   */

  useQuery<AxiosResponse<{ user: TUser }>, AxiosError>(
    ['user'],
    async () => {
      return axios.get(api.me.index, {
        headers: { Authorization: `Bearer ${jwtToken}` }
      })
    },
    {
      enabled: !!jwtToken,
      onSuccess: (response) => {
        const { user } = response.data
        setUser(user)
      }
    }
  )

  /**
   *
   * IS Authenticated
   * Double check if the jwt token and access to is there
   *
   */

  const _isAuthenticated = !!jwtToken && !!moralisAccessToken && isAuthenticated

  /**
   *
   * Create Authorization header
   *
   */

  const Authorization = { Authorization: `Bearer ${jwtToken}` }

  /**
   *
   * Email verified
   *
   */
  const isEmailVerified =
    (moralisUser?.attributes?.emailVerified as boolean) || false

  /**
   *
   * Email
   *
   */
  const email = (moralisUser?.attributes?.email as string) || null

  /**
   *
   *   Save external wallet
   *
   */

  const saveExternalWallet = useMutation<
    AxiosResponse<{ user: TUser }>,
    AxiosError,
    TSaveWalletDto
  >(
    async (data) => {
      return await axios.post(api.me.wallet, data, {
        headers: Authorization
      })
    },
    {
      onSuccess: (response) => {
        const { user } = response.data
        toast.success(`Your wallet address has been successfully saved`)
        setUser(user)
      },
      onError: () => {
        toast.error(`We had an issue saving your wallet address`)
      }
    }
  )

  /**
   *
   *  Verify email
   *
   */

  const verifyEmail = useMutation<
    AxiosResponse<any>,
    AxiosError,
    TVerifyEmailDto
  >(async (data) => {
    return await axios.post(api.me.email, data, {
      headers: Authorization
    })
  })

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          Authorization,
          isEmailVerified,
          isAuthenticated: _isAuthenticated,
          saveExternalWallet,
          destroyUser,
          verifyEmail,
          email
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  )
}

export default UserContextProvider
