import { Country } from '@models/countries/interfaces/country.interface'
import countries from './countries.json'
import { createContext } from 'react'

interface IContext {
  countries: Array<Country> | []
}

export const CountriesContext = createContext<IContext>({ countries: [] })

const CountriesContextProvider = ({ children }) => {
  return (
    <CountriesContext.Provider value={{ countries }}>
      {children}
    </CountriesContext.Provider>
  )
}

export default CountriesContextProvider
