import useUser from '@contexts/UserContext/hooks/useUser'
import { Button, Modal, ModalFooter, Typography } from '@jeromevvb/equinox-lib'
import Network from '@models/projects/enums/network.enum'
import { ethers } from 'ethers'
import { JsonRpcSigner } from 'moralis/node_modules/@ethersproject/providers'
import { createContext, ReactNode, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useMoralis } from 'react-moralis'
import { AuthenticateOptions } from 'react-moralis/lib/hooks/core/useMoralis/_useMoralisAuth'
import evms from './evm.json'
import { getChainIdFromNetwork, getNetworkFromChainId } from './helpers'
import { TAddTokenParams, TWallet } from './types'

export const WalletContext = createContext<TWallet>({
  mainAccount: '',
  chainId: null,
  network: null,
  accounts: [],
  signer: null,
  removeWallet: null,
  addToken: null,
  connect: null,
  web3Provider: null,
  changeNetwork: null,
  disconnect: null
})

const WalletContextProvider = ({ children }: { children: ReactNode }) => {
  const [signer, setSigner] = useState<JsonRpcSigner>(null)
  const [mainAccount, setMainAccount] = useState<string>(null)
  const [accounts, setAccounts] = useState<string[]>([])
  const [web3Provider, setWeb3Provider] =
    useState<ethers.providers.Web3Provider>(null)
  const [linkAccountRequest, setLinkAccountRequest] = useState<boolean>(false)
  const [linkErrorCode, setLinkErrorCode] = useState<number>(null)
  const user = useUser()
  const {
    authenticate,
    logout,
    isAuthenticated,
    chainId: _chainId,
    account,
    refetchUserData,
    user: moralisUser,
    Moralis
  } = useMoralis()

  const chainId = parseInt(_chainId)
  const network = getNetworkFromChainId(chainId)

  /**
   *  Init web3
   *  This useEffect init web3 necessary data if the user is authenticated
   */
  useEffect(() => {
    const initWeb3 = async () => {
      // request web3 object
      const web3Provider = await Moralis.enableWeb3()
      // Chain ID
      // get signer
      const signer = web3Provider.getSigner()

      setSigner(signer)
      setWeb3Provider(web3Provider)
    }

    // if the user is not authenticated, we don't init web3
    // it means that he has never connect his wallet and we dont want to force the connection
    if (!isAuthenticated) return

    initWeb3()
    refetchUserData()
  }, [isAuthenticated, Moralis, chainId])

  // Wallet change
  useEffect(() => {
    if (!isAuthenticated || !moralisUser) return
    const accounts = moralisUser.attributes?.accounts || []

    setAccounts(accounts)
    if (!accounts.includes(account)) return

    setMainAccount(account)
  }, [account, isAuthenticated, moralisUser])

  // Associated a new account
  useEffect(() => {
    if (!isAuthenticated || !moralisUser) return

    const onAccountChanged = Moralis.onAccountChanged(async (account) => {
      const accounts = moralisUser.attributes?.accounts || []

      if (accounts.includes(account)) return setLinkAccountRequest(false)
      setLinkAccountRequest(true)
      setLinkErrorCode(null)
    })

    return () => {
      if (onAccountChanged) onAccountChanged()
    }
  }, [isAuthenticated, Moralis, moralisUser, account])

  // Save wallet
  const saveWallet = async () => {
    try {
      const user = await Moralis.link(account, {
        //@ts-ignore
        signingMessage: 'Please sign to confirm your wallet'
      })

      const newAccounts = user.attributes?.accounts || []
      setLinkAccountRequest(false)
      setMainAccount(account)
      setAccounts(newAccounts)
    } catch (error) {
      const code = error?.code || 500
      setLinkErrorCode(code)
    }
  }

  // unlink wallet
  const removeWallet = async (account: string) => {
    const user = await Moralis.unlink(account)
    const newAccounts = user.attributes?.accounts || []
    setAccounts(newAccounts)
  }

  /**
   * Connect wallet
   * @param useWalletConnect Will use walletConnect
   * @returns
   */
  const connect = async (useWalletConnect = false) => {
    if (!useWalletConnect && !(window as any)?.ethereum) {
      return toast.error('Metamask extension not found')
    }

    try {
      let options: AuthenticateOptions = {
        signingMessage: 'Equinox Web 3.0 Authentification'
      }

      if (useWalletConnect) {
        options = { ...options, provider: 'walletConnect' }
      }

      const user = await authenticate(options)
      const newAccounts = user?.attributes?.accounts || []
      setAccounts(newAccounts)
    } catch (error) {
      toast.error(error)
    }
  }

  /**
   * ----------
   *  DisConnect wallet
   * ----------
   */

  const disconnect = () => {
    logout()
    setMainAccount(null)
    user.destroyUser()
  }
  /**
   * Change network
   * @param network
   */
  const changeNetwork = async (network: Network) => {
    const chainId = getChainIdFromNetwork(network).toString(16)
    const chainIdHex = `0x${chainId}`
    try {
      await Moralis.switchNetwork(chainIdHex)
    } catch (error) {
      if (error.code !== 4902) {
        return toast.error(error.message)
      }

      const {
        chainName,
        currencyName,
        currencySymbol,
        rpcUrl,
        blockExplorerUrl
      } = evms[chainId]

      await Moralis.addNetwork(
        chainIdHex,
        chainName,
        currencyName,
        currencySymbol,
        rpcUrl,
        blockExplorerUrl
      )

      // switch to network
      changeNetwork(network)
    }
  }

  /**
   * Add token using watchAsset method
   */
  const addToken = async (options: TAddTokenParams) => {
    if (!(window as any).ethereum) return
    await (window as any).ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options
      }
    })
  }

  return (
    <>
      <Modal
        backdropClose={false}
        show={linkAccountRequest}
        setShow={setLinkAccountRequest}
        title="We've detected a new wallet"
      >
        {/* Moralis code if the wallet is already linked to another account */}
        {linkErrorCode === 208 && (
          <>
            <Typography color="error" className="mb-2 text-center">
              The wallet you are trying to save belongs to another user.
            </Typography>
          </>
        )}
        {!linkErrorCode && (
          <>
            <Typography>We must save your wallet in our system.</Typography>
            <Typography color="muted" className="mb-2" caption>
              Within the Equinox Ecosystem, you can authenticate with multiple
              wallets.
            </Typography>

            <Typography color="muted" caption>
              If you don't save your wallet, you won't be able to use it under
              the same user account.
            </Typography>
            <ModalFooter
              buttonSecondary={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveWallet}
                >
                  Save my wallet
                </Button>
              }
            />
          </>
        )}
      </Modal>
      <WalletContext.Provider
        value={{
          connect,
          mainAccount,
          network,
          addToken,
          disconnect,
          removeWallet,
          changeNetwork,
          signer,
          web3Provider,
          accounts,
          chainId
        }}
      >
        {children}
      </WalletContext.Provider>
    </>
  )
}

export default WalletContextProvider
