import NextLink from 'next/link'
import { Button } from '@jeromevvb/equinox-lib'
import React from 'react'
import Banner from '../Banner'

const KYCDeclinedBanner: React.FC = () => {
  const cta = (
    <NextLink href={'/kyc'}>
      <Button color="secondary" size="small" variant="contained">
        Try again
      </Button>
    </NextLink>
  )

  return (
    <Banner
      text="Your KYC application was declined. Please try again."
      cta={cta}
    />
  )
}

export default KYCDeclinedBanner
