import Layout from '@components/layout'
import AppContext from '@contexts/AppContext/AppContext'
import ProjectsContextProvider from '@contexts/ProjectsContext/ProjectsContext'
import '@styles/globals.css'
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import Head from 'next/head'
import { useEffect } from 'react'

// node-fetch polyfill
// Next prefetches Apollo data using node-fetch
global.fetch = abortableFetch(fetch).fetch

dayjs.extend(relativeTime)
dayjs.extend(utc)

function App({ Component, pageProps, router }) {
  // Make sure pages scroll to the top after we navigate to them using `next/router`
  // useRouterScroll()
  const { locale, route } = router

  useEffect(() => {
    dayjs.locale(locale)
  }, [locale])

  const isGuildJoinRoute = route === '/account/join'
  const isKycRoute = route === '/kyc'
  const padded = route === '/project/[slug]' || isGuildJoinRoute ? false : true
  const headerTransparentOnMount = isGuildJoinRoute
  const showFooter = !isGuildJoinRoute && !isKycRoute

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>

      {/* <PageError maintenance> */}
      <ProjectsContextProvider>
        <AppContext locale={locale}>
          <Layout
            padded={padded}
            showFooter={showFooter}
            headerTransparentOnMount={headerTransparentOnMount}
          >
            <Component {...pageProps} />
          </Layout>
        </AppContext>
      </ProjectsContextProvider>
      {/* </PageError> */}
    </>
  )
}

export default App
