const engine = process.env.NEXT_PUBLIC_ENGINE || 'http://127.0.0.1:3000'

export default {
  token: `${engine}/api/users/token`,
  revokeToken: `${engine}/api/users/revoke`,
  me: {
    index: `${engine}/api/users/me`,
    email: `${engine}/api/users/me/email`,
    kyc: `${engine}/api/users/me/kyc`,
    wallet: `${engine}/api/users/me/wallet`
  },

  projects: {
    participations: (slug: string, account: string) =>
      `${engine}/api/projects/${slug}/participation/${account}`,
    participate: `${engine}/api/projects/participation`
  }
}
