import { useEffect, useState } from 'react'
import { useMoralis } from 'react-moralis'
import axios from 'axios'
import api from '@config/api'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'

const useJwt = () => {
  const [jwtToken, setJwtToken] = useState<string>(null)
  const { user: moralisUser, isAuthenticated } = useMoralis()

  const moralisAccessToken = moralisUser?.attributes?.accessToken || null
  /**
   *
   * JWT TOKEN
   * Get the jwt token from the localstorage or request one.
   *
   */
  useEffect(() => {
    if (!isAuthenticated || !moralisAccessToken) return

    // get cookie
    const jwtToken = Cookies.get(moralisAccessToken)

    // if local storage
    if (jwtToken) return setJwtToken(jwtToken)

    const getToken = async () => {
      try {
        const request = await axios.post(
          api.token,
          {},
          {
            headers: {
              'Access-Token': moralisAccessToken
            }
          }
        )

        const { token, expiresIn } = request.data

        setJwtToken(token)

        const expires = new Date(new Date().getTime() + expiresIn)
        Cookies.set(moralisAccessToken, token, {
          expires,
          secure: true,
          domain: '.equinox.fund'
        })
      } catch (error) {
        toast.error('Authentification failed. Please try again')
      }
    }

    getToken()
  }, [isAuthenticated, moralisAccessToken])

  /**
   * Destroy cookie and call logout
   */
  const destroy = () => {
    axios
      .post(
        api.revokeToken,
        {},
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`
          }
        }
      )
      .then(() => {
        Cookies.remove(moralisAccessToken)
        setJwtToken(null)
      })
  }

  return { destroy, jwtToken }
}

export default useJwt
