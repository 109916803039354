import NextLink from 'next/link'
import { Button } from '@jeromevvb/equinox-lib'
import React from 'react'
import Banner from '../Banner'

const KYCNotStartedBanner: React.FC = () => {
  const cta = (
    <NextLink href={'/kyc'}>
      <Button color="secondary" size="small" variant="contained">
        Complete KYC
      </Button>
    </NextLink>
  )

  return (
    <Banner
      text="You must complete KYC to participate in Equinox IDOs."
      cta={cta}
    />
  )
}

export default KYCNotStartedBanner
