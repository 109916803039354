import tw, { css } from 'twin.macro'

const openStyles = css`
  opacity: 100;
  transform: translateY(0%);
`

const styles = ({ show }) => css`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  transition: opacity;
  ${tw`duration-slow`};
  ${tw`z-mobile-menu`};
  ${tw`pt-40`};
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);

  ${tw`lg:hidden`};

  ${show && openStyles};

  nav {
    ${tw`w-11/12`};
    ${tw`xs:w-10/12`};
    ${tw`sm:w-7/12`};
    text-align: center;
  }

  .nav-link {
    ${tw`mx-auto`}
    margin-bottom: 2.5rem;
    cursor: pointer;
  }

  button.inline-block {
    display: inline-block;
    width: auto;
  }

  .menu-socials {
    display: flex;
    ${tw`items-center`}
    ${tw`justify-center`}
    ${tw`space-x-6`}
    position: absolute;
    bottom: 3rem;
    width: 100%;
  }

  .social-link {
    ${tw`text-neutral-silver`}
    transition: color;
    ${tw`text-body-sm`}
    ${tw`md:text-caption-md`}

    &:hover {
      ${tw`text-secondary`}
    }
  }
`

export default styles
