import { gql } from '@apollo/client'

export const settingsFragment = gql`
  fragment settingsFields on Settings {
    footerDescription
    mediumUrl
    twitterUrl
    youTubeUrl
    telegramUrl
    instagramUrl
  }
`
