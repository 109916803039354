import ConnectedWallet from '@components/common/ConnectedWallet'
import ConnectWalletButton from '@components/layout/Header/ConnectWalletButton'
import useKYC from '@contexts/KYCContext/hooks/useKYC'
import { KYCStatus } from '@contexts/KYCContext/types'
import useUser from '@contexts/UserContext/hooks/useUser'
import useWallet from '@contexts/WalletContext/hooks/useWallet'
import { Button, MenuButton } from '@jeromevvb/equinox-lib'
import LogoSvg from '@public/logo-mark.svg'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import EmailVerifyBanner from '../EmailVerifyBanner'
import KYCDeclinedBanner from '../KYCDeclinedBanner'
import KYCNotStartedBanner from '../KYCNotStartedBanner'
import OfflineBanner from '../OfflineBanner'
import MobileMenu from './MobileMenu'
import styles from './styles'

const Header = ({ transparentOnMount = false }) => {
  const wallet = useWallet()

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [scrolled, setScrolled] = useState<boolean>(false)
  const { isEmailVerified, isAuthenticated } = useUser()
  const { status } = useKYC()
  const { y: scrollY } = useWindowScroll()

  const handleLogout = () => {
    wallet.disconnect()
  }

  useEffect(() => {
    if (scrollY > 30 && !scrolled) {
      setScrolled(true)
    } else if (scrollY <= 30 && scrolled) {
      setScrolled(false)
    }
  }, [scrollY])

  return (
    <header css={styles({ scrolled, transparentOnMount })}>
      <OfflineBanner />
      {isAuthenticated && (
        <>
          {!isEmailVerified && <EmailVerifyBanner />}
          {isEmailVerified && (
            <>
              {status === KYCStatus.DECLINED && <KYCDeclinedBanner />}
              {status === KYCStatus.NOT_STARTED && <KYCNotStartedBanner />}
            </>
          )}
        </>
      )}

      <div className="header-inner container">
        <div className="header-left">
          <Link href="/" passHref>
            <a onClick={() => setShowMenu(false)}>
              <img src={LogoSvg.src} alt="Equinox" className="logo" />
            </a>
          </Link>

          <a
            href="https://guild.equinox.fund"
            target="_blank"
            title="Guild"
            rel="noreferrer"
            className="nav-link"
          >
            <Button color="secondary" variant="text">
              Guild
            </Button>
          </a>
          <a
            href="https://marketplace.equinox.fund"
            target="_blank"
            title="Equinox Marketplace"
            rel="noreferrer"
            className="nav-link"
          >
            <Button color="secondary" variant="text">
              Marketplace
            </Button>
          </a>

          <Link passHref href="/faq">
            <a title="FAQ" className="nav-link">
              <Button color="secondary" variant="text">
                FAQ
              </Button>
            </a>
          </Link>
        </div>
        <div className="header-right">
          <>
            {!wallet.mainAccount && (
              <div className="hidden lg:block">
                <ConnectWalletButton />
              </div>
            )}

            {wallet.mainAccount && (
              <div className="hidden lg:block">
                <ConnectedWallet />
              </div>
            )}
          </>

          <div className="menu-btn">
            <MenuButton
              open={showMenu}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
        </div>
      </div>
      <MobileMenu show={showMenu} setShow={setShowMenu} logout={handleLogout} />
    </header>
  )
}

export default Header
