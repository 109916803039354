import tw, { css, theme } from 'twin.macro'

const styles = css`
  background-color: ${theme`colors.system.warning`};
  ${tw`z-offlineDetector`};
  ${tw`py-1 px-1`};
  text-align: center;
`

export default styles
