import tw, { css, theme } from 'twin.macro'

const bgStyles = css`
  ${tw`bg-black`}
  ${tw`bg-opacity-60`}
  backdrop-filter: blur(10px);
`

const styles = ({ scrolled, transparentOnMount }) => css`
  width: 100%;
  ${tw`z-header`}
  position: fixed;
  top: 0;

  ${transparentOnMount && scrolled && bgStyles}
  ${!transparentOnMount && bgStyles}
  

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${tw`mb-0`}
    ${scrolled ? tw`py-3` : tw`py-4`}
    transition: padding;
    ${tw`duration-slow`}
  }

  .header-left {
    ${tw`flex`}
    align-items: center;
  }

  .header-right {
    ${tw`flex`}
    align-items: center;
    ${tw`space-x-2`}
    ${tw`lg:space-x-4`}
  }
  .nav-link {
    ${tw`hidden`}
    ${tw`lg:block`}
  }

  .menu-btn {
    ${tw`lg:hidden`}
  }

  .link-icon {
    margin-right: 0.5rem;
    ${tw`w-6`}
  }

  .logo {
    width: ${scrolled ? '3.5rem' : '4rem'};
    transition: width;
    ${tw`duration-slow`}
    ${tw`mr-4`}

    @media (min-width: ${theme`screens.md`}) {
      width: ${scrolled ? '4.5rem' : '6rem'};
    }
  }
`

export default styles
