import OwnedNft from '@components/layout/Header/OwnedNft'
import {
  BinanceSmartChain as BscIcon,
  Button,
  Divider,
  IconButton,
  MenuDropdown,
  Modal,
  Spinner,
  Typography
} from '@jeromevvb/equinox-lib'
import truncateAddress from '@helpers/truncateAddress'
import useWallet from '@contexts/WalletContext/hooks/useWallet'
import React, { useState } from 'react'
import { RiDeleteBack2Line } from 'react-icons/ri'
import styles from './styles'
import useMyNFT from '@contexts/NFTContractContext/hooks/useMyNFT'

const ConnectedWallet: React.FC = () => {
  const { mainAccount, disconnect, removeWallet, accounts } = useWallet()
  const { artworks, isFetching } = useMyNFT()
  const [showWalletsModal, setShowWalletsModal] = useState(false)

  const handleDisconnect = () => {
    disconnect()
  }

  const handleShowWallets = () => {
    setShowWalletsModal(true)
  }

  const items = [
    {
      children: (
        <div>
          {isFetching && (
            <div className="flex justify-center my-4">
              <Spinner size="small" />
            </div>
          )}
          {!isFetching && (
            <div className="nfts-owned">
              {artworks && artworks.length > 0 && (
                <div className="flex space-x-0.5">
                  {artworks.map((artwork, key) => (
                    <OwnedNft
                      key={key}
                      tokenId={artwork.tokenId}
                      amount={artwork.amount}
                    />
                  ))}
                </div>
              )}
              {artworks.length < 4 && (
                <a
                  href="https://marketplace.equinox.fund"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="nfts-buy"
                />
              )}
            </div>
          )}
        </div>
      )
    },
    {
      name: 'showWallets',
      label: 'My wallets',
      action: handleShowWallets,
      divider: true
    },
    {
      name: 'disconnect',
      label: 'Log out',
      action: handleDisconnect
    }
  ]

  return (
    <>
      <Modal
        show={showWalletsModal}
        setShow={setShowWalletsModal}
        title="My wallets"
      >
        <div>
          {accounts.map((account) => {
            return (
              <div key={account}>
                <div className="flex items-center flex-row">
                  <div className="flex-1">
                    <Typography>
                      {truncateAddress(account, 5).toLocaleUpperCase()}
                    </Typography>
                  </div>
                  <div>
                    {mainAccount !== account && (
                      <div>
                        <IconButton
                          title={'Remove wallet'}
                          onClick={() => removeWallet(account)}
                          icon={<RiDeleteBack2Line size={22} />}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Divider horizontal className="my-3" />
              </div>
            )
          })}
        </div>
        <Typography caption color="muted" className="text-center mt-10">
          Please note that if you remove a wallet, you won't be able to
          authenticate under this wallet anymore.
        </Typography>
      </Modal>
      <div css={styles} data-testid="button-wallet-connected">
        <MenuDropdown
          items={items}
          className="menu-dropdown"
          dropdownAlign="right"
        >
          <Button
            variant="outlined"
            color="secondary"
            iconPosition="left"
            icon={BscIcon}
          >
            {truncateAddress(mainAccount, 4).toUpperCase()}
          </Button>
        </MenuDropdown>
      </div>
    </>
  )
}

export default ConnectedWallet
