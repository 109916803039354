import { TWallet } from '@contexts/WalletContext/types'
import { WalletContext } from '@contexts/WalletContext/'
import { useContext } from 'react'

const useWallet = (): TWallet => {
  const wallet = useContext(WalletContext) as TWallet

  return wallet
}

export default useWallet
