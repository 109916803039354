import { ChainId } from '@jeromevvb/equinox-lib'
import { ethers } from 'ethers'

type ProviderType = ethers.providers.WebSocketProvider

type WebsocketsConnections = {
  [chainId: number]: ProviderType
}

class ChainWebSockets {
  public connections: WebsocketsConnections = {}

  constructor() {}

  addConnection(chainId: number, websocket: ProviderType) {
    this.connections = { ...this.connections, [chainId]: websocket }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
  isConnected(connection: ProviderType, chainId: ChainId) {
    if (chainId === ChainId.BSC) {
      return true
    }

    const readyState = (connection as ethers.providers.WebSocketProvider)
      ?._websocket?.readyState

    return readyState <= 2
  }

  getConnection(chainId: number): ProviderType | null {
    const connection: ProviderType = this.connections[chainId]

    if (!connection) return null

    if (!this.isConnected(connection, chainId)) return null

    return connection
  }
}

export default ChainWebSockets
