import CountriesContext from '@contexts/CountriesContext/CountriesContext'
import I18nContextProvider from '@contexts/I18nContext/I18nContext'
import KYCContextProvider from '@contexts/KYCContext'
import NFTContractContextProvider from '@contexts/NFTContractContext'
import SettingsContextProvider from '@contexts/SettingsContext'
import UserContextProvider from '@contexts/UserContext'
import WalletContextProvider from '@contexts/WalletContext'
import { MoralisProvider } from 'react-moralis'
import { QueryClient, QueryClientProvider } from 'react-query'
import { IntercomProvider } from 'react-use-intercom'

export interface AppContextsProps {
  locale?: string
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: true,
      retry: 1
    }
  }
})

const AppContexts: React.FC<AppContextsProps> = ({ locale, children }) => {
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_ID
  return (
    <IntercomProvider appId={intercomAppId} autoBoot>
      <I18nContextProvider locale={locale}>
        <MoralisProvider
          appId={process.env.NEXT_PUBLIC_MORALIS_APP_ID}
          serverUrl={process.env.NEXT_PUBLIC_MORALIS_SERVER_URL}
        >
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <KYCContextProvider>
                <WalletContextProvider>
                  <SettingsContextProvider>
                    <NFTContractContextProvider>
                      <CountriesContext>{children}</CountriesContext>
                    </NFTContractContextProvider>
                  </SettingsContextProvider>
                </WalletContextProvider>
              </KYCContextProvider>
            </UserContextProvider>
          </QueryClientProvider>
        </MoralisProvider>
      </I18nContextProvider>
    </IntercomProvider>
  )
}

export default AppContexts
