import api from '@config/api'
import useUser from '@contexts/UserContext/hooks/useUser'
import axios, { AxiosError } from 'axios'
import { createContext, ReactNode } from 'react'
import { useQuery } from 'react-query'
import { KYCStatus, TKYCContext, TKYCResponseData, TKYCStatus } from './types'

export const KYCContext = createContext<TKYCContext>({
  status: null,
  isApproved: false,
  country: null
})

const KYCContextProvider = ({ children }: { children: ReactNode }) => {
  const { Authorization, isAuthenticated, isEmailVerified } = useUser()

  /**
   *
   * Get KYC
   * Only if email has been verified
   */

  const { data } = useQuery<
    { status: TKYCStatus; country: string },
    AxiosError
  >(
    ['kyc'],
    async () => {
      try {
        const request = await axios.get<TKYCResponseData>(api.me.kyc, {
          headers: Authorization
        })

        return { status: request.data?.status, country: request.data?.country }
      } catch (error) {
        return { status: 'NOT_STARTED', country: null }
      }
    },
    {
      enabled: isEmailVerified && isAuthenticated
    }
  )

  const status = data?.status
  const country = data?.country
  const isApproved = status === KYCStatus.APPROVED

  return (
    <>
      <KYCContext.Provider
        value={{
          status,
          country,
          isApproved
        }}
      >
        {children}
      </KYCContext.Provider>
    </>
  )
}

export default KYCContextProvider
