import { Toaster } from 'react-hot-toast'
import { theme } from 'twin.macro'

const Notifications = () => {
  return (
    <Toaster
      position="bottom-left"
      toastOptions={{
        style: {
          borderRadius: '5px',
          background: '#ffffff',
          color: '#000',
          padding: '8px 10px',
          maxWidth: '56rem',
          fontSize: '1.2rem',
          border: `1px solid ${theme`colors.secondary`}`
        }
      }}
    />
  )
}

export default Notifications
