import { Button, Typography, Skeleton } from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import LogoSvg from '@public/logo.svg'
import useSettings from '@contexts/SettingsContext/hooks/useSettings'
import SocialLinks from './SocialLinks'
import NextLink from 'next/link'
import styles from './styles'

const Footer = () => {
  const { t } = useTranslation('layout')
  const { footerDescription } = useSettings()

  return (
    <footer className="container" css={styles}>
      <div className="footer-inner">
        <div className="brand">
          <img src={LogoSvg.src} alt="Equinox" />
          {footerDescription ? (
            <Typography size="medium" caption className="footer-text">
              {footerDescription}
            </Typography>
          ) : (
            <>
              <Skeleton className="w-full h-4 mb-2" />
              <Skeleton className="w-24 h-4 mb-6" />
            </>
          )}

          <div className="footer-legals">
            <NextLink href="/faq" passHref={true}>
              <a title={t('footer.faq')}>
                <Typography
                  color="muted"
                  size="medium"
                  caption
                  className="footer-link"
                  hoverAsLink
                >
                  {t('footer.faq')}
                </Typography>
              </a>
            </NextLink>
            <a
              href="https://static.equinox.fund/legal/terms-of-service.pdf"
              target="_blank"
              rel="noreferrer noopener"
              title={t('footer.terms')}
            >
              <Typography
                color="muted"
                size="medium"
                caption
                className="footer-link"
                hoverAsLink
              >
                {t('footer.terms')}
              </Typography>
            </a>

            <a
              href="https://static.equinox.fund/legal/privacy-policy.pdf"
              target="_blank"
              rel="noreferrer noopener"
              title={t('footer.privacy')}
            >
              <Typography
                color="muted"
                size="medium"
                caption
                className="footer-link"
                hoverAsLink
              >
                {t('footer.privacy')}
              </Typography>
            </a>
          </div>
        </div>
        <nav className="nav">
          <div className="nav-links">
            <div className="footer-launch">
              <a
                href="https://growstudio.typeform.com/to/SDgCtEaW"
                target="_blank"
                rel="noreferrer noopener"
                title="Launch your project on Equinox"
                className="hover-as-link"
              >
                <Button variant="outlined">Launch your project</Button>
              </a>
            </div>
          </div>
          <SocialLinks className="justify-center md:justify-end" />
        </nav>
      </div>
    </footer>
  )
}

export default Footer
