import { Image, Modal, Typography } from '@jeromevvb/equinox-lib'
import React, { useState } from 'react'
import styles from './styles'

import Autumnal from '@public/nft/autumnal.jpg'
import Winter from '@public/nft/winter.jpg'
import Vernal from '@public/nft/vernal.jpg'
import Summer from '@public/nft/summer.jpg'
import GuildAmbassador from '@public/nft/guild-ambassador.jpeg'
import GuildOfficer from '@public/nft/guild-officer.jpeg'

export interface OwnedNftProps {
  tokenId: number
  amount: number
}

const nfts = {
  1: {
    name: 'NOX Autumnal',
    image: Autumnal,
    description: 'NOX Tier 1 Key'
  },
  2: {
    name: 'NOX Winter',
    image: Winter,
    description: 'NOX Tier 2 Key'
  },
  3: {
    name: 'NOX Vernal',
    image: Vernal,
    description: 'NOX Tier 3 Key'
  },
  4: {
    name: 'NOX Summer',
    image: Summer,
    description: 'Equinox Super NFT'
  },
  5: {
    name: 'Guild Ambassador',
    image: GuildAmbassador,
    description: 'Equinox Guild Ambassador'
  },
  6: {
    name: 'Guild Officer',
    image: GuildOfficer,
    description: 'Equinox Guild Officer'
  }
}

const OwnedNft: React.FC<OwnedNftProps> = ({ tokenId, amount }) => {
  const [showArtwork, setShowArtwork] = useState(false)
  const nftCount = amount > 10 ? '10+' : amount.toString()

  const data = nfts[tokenId]

  return (
    <>
      <Modal
        show={showArtwork}
        setShow={() => setShowArtwork(false)}
        size="small"
      >
        {showArtwork && (
          <div className="nft-modal">
            <div className="xxs:flex justify-between mb-4">
              <Typography weight="semibold" className="mb-1">
                {data?.name}
              </Typography>
              <Typography color="muted" size="small" className="mb-4">
                {amount === 1 ? 'Owned by you' : `You own ${amount}`}
              </Typography>
            </div>
            <Image
              src={data?.image.src}
              alt={data?.name}
              className="mb-8 border border-neutral-stone rounded-lg overflow-hidden"
            />
            {/* <Typography className="mb-1">{data?.description}</Typography> */}
          </div>
        )}
      </Modal>
      <button
        css={styles({ hasMultiple: amount > 1 })}
        onClick={() => setShowArtwork(true)}
      >
        <div className="nft-artwork">
          <Image src={data?.image.src} alt={data?.name} />
        </div>
        <div className="nft-count">{nftCount}</div>
      </button>
    </>
  )
}

export default OwnedNft
