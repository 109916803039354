import nftAbi from '@abi/nft.contract.json'
import { NFT_IDS_BY_NAME, SUPPORTED_NFT_IDS } from '@config/marketplace'
import useWallet from '@contexts/WalletContext/hooks/useWallet'
import { ChainId } from '@jeromevvb/equinox-lib'
import { ethers } from 'ethers'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useMoralisWeb3Api } from 'react-moralis'
import { useIsFetching, useQuery } from 'react-query'

export interface INFTContract {
  isFetching: boolean
  artworks: TArtwork[]
  autumnal: boolean
  winter: boolean
  vernal: boolean
  summer: boolean
}

export type TArtwork = {
  amount: number
  tokenId: number
}

export const NFTContractContext = createContext<INFTContract>({
  isFetching: false,
  artworks: [],
  autumnal: false,
  winter: false,
  vernal: false,
  summer: false
})

const NFTContractContextProvider = ({ children }: { children: ReactNode }) => {
  const { mainAccount, chainId, signer } = useWallet()
  const [autumnal, setAutumnal] = useState(false)
  const [winter, setWinter] = useState(false)
  const [vernal, setVernal] = useState(false)
  const [summer, setSummer] = useState(false)
  const MoralisWeb3Api = useMoralisWeb3Api()

  const isFetchingTimes = useIsFetching(['nfts'])
  const isFetching = isFetchingTimes > 0

  const { data: marketplaceNfts } = useQuery(
    ['nfts', 'marketplace', mainAccount],
    async () => {
      try {
        let result = []
        if (chainId === ChainId.BSC) {
          const contract = new ethers.Contract(
            process.env.NEXT_PUBLIC_NFT_CONTRACT_EQUINOX,
            nftAbi,
            signer
          )

          const request = async (mainAccount: string, token_id: string) => {
            const amount = await contract.balanceOf(mainAccount, token_id)
            return { amount: amount.toString(), token_id }
          }

          result = await Promise.all(
            Object.values(NFT_IDS_BY_NAME).map((token_id) =>
              request(mainAccount, token_id)
            )
          )
        } else {
          const request = await MoralisWeb3Api.account.getNFTsForContract({
            chain: 'bsc',
            address: mainAccount,
            token_address: process.env.NEXT_PUBLIC_NFT_CONTRACT_EQUINOX
          })

          result = request.result
        }

        return result.reduce((state, { token_id, amount }) => {
          if (parseInt(amount) === 0) return state
          if (!SUPPORTED_NFT_IDS[token_id]) return state

          return [
            ...state,
            {
              tokenId: SUPPORTED_NFT_IDS[token_id],
              amount: parseInt(amount)
            }
          ]
        }, [])
      } catch (error) {
        return []
      }
    },
    {
      enabled: !!mainAccount,
      placeholderData: [],
      staleTime: Infinity
    }
  )

  useEffect(() => {
    setAutumnal(false)
    setWinter(false)
    setVernal(false)
    setSummer(false)

    const autumnal = marketplaceNfts.filter(
      ({ tokenId }) => tokenId === 1
    ).length
    const winter = marketplaceNfts.filter(({ tokenId }) => tokenId === 2).length
    const vernal = marketplaceNfts.filter(({ tokenId }) => tokenId === 3).length
    const summer = marketplaceNfts.filter(({ tokenId }) => tokenId === 4).length

    if (autumnal > 0) setAutumnal(true)
    if (winter > 0) setWinter(true)
    if (vernal > 0) setVernal(true)
    if (summer > 0) setSummer(true)
  }, [marketplaceNfts])

  return (
    <NFTContractContext.Provider
      value={{
        isFetching,
        artworks: marketplaceNfts,
        autumnal,
        winter,
        vernal,
        summer
      }}
    >
      {children}
    </NFTContractContext.Provider>
  )
}

export default NFTContractContextProvider
