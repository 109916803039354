// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'

Sentry.init({
  dsn: 'https://0134bc9e32d04205b826efe9bf4c2693@o1007873.ingest.sentry.io/5970982',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  environment
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
