import { AiFillInstagram } from 'react-icons/ai'
import {
  FaMediumM,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa'
import useSettings from '@contexts/SettingsContext/hooks/useSettings'
import styles from './styles'

interface SocialLinksProps {
  className?: string
}

const SocialLinks = ({ className = '' }: SocialLinksProps) => {
  const settings = useSettings()
  const { mediumUrl, twitterUrl, youTubeUrl, telegramUrl, instagramUrl } =
    settings

  return settings ? (
    <div css={styles} className={className}>
      {mediumUrl && (
        <a
          href={mediumUrl}
          target="_blank"
          rel="noreferrer noopener"
          title="Medium"
          className="link"
        >
          <FaMediumM size={28} />
        </a>
      )}
      {twitterUrl && (
        <a
          href={twitterUrl}
          target="_blank"
          rel="noreferrer noopener"
          title="Twitter"
          className="link"
        >
          <FaTwitter size={28} />
        </a>
      )}
      {youTubeUrl && (
        <a
          href={youTubeUrl}
          target="_blank"
          rel="noreferrer noopener"
          title="YouTube"
          className="link"
        >
          <FaYoutube size={28} />
        </a>
      )}
      {telegramUrl && (
        <a
          href={telegramUrl}
          target="_blank"
          rel="noreferrer noopener"
          title="Telegram"
          className="link"
        >
          <FaTelegramPlane size={28} />
        </a>
      )}
      {instagramUrl && (
        <a
          href={instagramUrl}
          target="_blank"
          rel="noreferrer noopener"
          title="Instagram"
          className="link"
        >
          <AiFillInstagram size={28} />
        </a>
      )}
    </div>
  ) : null
}

export default SocialLinks
