import tw, { css } from 'twin.macro'

const styles = css`
  ${tw`flex`}
  ${tw`space-x-6`}
  ${tw`mb-6`}

  .link {
    ${tw`text-neutral-silver`}
    transition: color;
    ${tw`text-body-sm`}
    ${tw`md:text-caption-md`}

    &:hover {
      ${tw`text-secondary`}
    }
  }
`

export default styles
