import tw, { css } from 'twin.macro'

const styles = css`
  ${tw`mb-10`}
  ${tw`inline-block`}
  ${tw`lg:mb-0`}
  ${tw`lg:block`}

  .menu-dropdown {
    svg {
      ${tw`w-4`}
    }
  }

  .nfts-owned {
    ${tw`p-4`}
    ${tw`pb-3`}
    ${tw`flex`}
    ${tw`items-center`}
  }

  .nfts-buy {
    ${tw`w-10`}
    ${tw`h-10`}
    ${tw`border`}
    ${tw`border-dashed`}
    ${tw`border-neutral-silver`}
    ${tw`rounded-lg`}
    position: relative;
    ${tw`transition-colors`}
    ${tw`duration-base`}
    ${tw`flex-shrink-0`}
    ${tw`flex-grow-0`}
    ${tw`ml-1.5`}

    &:after {
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${tw`text-neutral-silver`}
      ${tw`transition-colors`}
      ${tw`duration-base`}
    }

    &:hover {
      ${tw`border-secondary`}

      &:after {
        ${tw`text-secondary`}
      }
    }
  }
`

export default styles
