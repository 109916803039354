import { useContext } from 'react'
import { I18nContext, Ti18nNamespaces } from '@contexts/I18nContext/I18nContext'

import getObjectProperty from '@helpers/getObjectProperty'
import smartReplace from '@helpers/smartReplace'

type TVariables = {
  [key: string]: any
}

type TDefaultText = string

const useTranslation = (namespace: Ti18nNamespaces = 'global') => {
  const { translations, locale } = useContext(I18nContext)

  /**
   * Translation function.
   * @param key
   * @param variables
   * @returns
   */
  const t = (
    key: string,
    variables?: TVariables,
    defaultText?: TDefaultText
  ): string => {
    const localizedKey = `${namespace}.${locale}.${key}`

    const translatedText =
      getObjectProperty(translations, localizedKey) || defaultText

    // if we haven't found the translated text.
    // we display an error text
    if (!translatedText) {
      return 'I18N_NOT_FOUND'
    }

    return smartReplace(translatedText, variables)
  }

  return { t, translations }
}

export default useTranslation
