import Footer from '@components/layout/Footer'
import Header from '@components/layout/Header'
import NextNProgress from 'nextjs-progressbar'
import { createContext, MutableRefObject, useRef } from 'react'
import Notifications from './Notifications'
import styles from './styles'

export interface LayoutProps {
  padded?: boolean
  headerTransparentOnMount?: boolean
  showFooter?: boolean
  children: React.ReactNode
}

interface IContext {
  tooltipContainerRef: MutableRefObject<any>
}

export const LayoutContext = createContext<IContext>({
  tooltipContainerRef: null
})

const Layout: React.FC<LayoutProps> = ({
  padded,
  showFooter,
  headerTransparentOnMount,
  children
}) => {
  const tooltipContainerRef = useRef(null)

  return (
    <LayoutContext.Provider value={{ tooltipContainerRef }}>
      <NextNProgress color="#fff" height={2} options={{ showSpinner: false }} />
      <main css={styles({ padded })}>
        <Notifications />
        <Header transparentOnMount={headerTransparentOnMount} />
        {children}
        {showFooter && <Footer />}
        <div ref={tooltipContainerRef}></div>
      </main>
    </LayoutContext.Provider>
  )
}

export default Layout
