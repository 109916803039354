import enContract from './locales/contract/en.json'
import enCountries from './locales/countries/en.json'
import enDashboard from './locales/dashboard/en.json'
import enGlobal from './locales/global/en.json'
import enLayout from './locales/layout/en.json'
import enProject from './locales/project/en.json'

import { createContext } from 'react'

interface IContext {
  translations: { [key: string]: any }
  locale: string
}

// TODO: Create a function that reads locale folder and build the following object:
const DEFAULT_TRANSLATIONS = {
  global: {
    en: enGlobal
  },
  dashboard: {
    en: enDashboard
  },
  layout: {
    en: enLayout
  },
  project: {
    en: enProject
  },
  countries: {
    en: enCountries
  },
  contract: {
    en: enContract
  }
}

export type Ti18nNamespaces =
  | 'global'
  | 'dashboard'
  | 'layout'
  | 'project'
  | 'contract'
  | 'countries'

export const I18nContext = createContext<Partial<IContext>>({})

const I18nContextProvider: React.FC<{
  locale: string
  translation?: { [key: string]: any }
  children: any
}> = ({ locale, translation, children }) => {
  // use custom translation file or the native one.
  const translations = translation || DEFAULT_TRANSLATIONS

  return (
    <I18nContext.Provider value={{ translations, locale }}>
      {children}
    </I18nContext.Provider>
  )
}

export default I18nContextProvider
