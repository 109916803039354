import getObjectProperty from '@helpers/getObjectProperty'
import dayjs from 'dayjs'

/**
 *
 * @param {*} projects
 * @param {*} sortType 'ASC' or 'DESC'
 * @returns
 */

const geUnix = (date: string) => {
  if (!date) return 0

  return dayjs(date).unix()
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC'
}

function sortByDate<T>(
  dataset: Array<T>,
  fieldName: string,
  sortType: SortType = SortType.ASC
): Array<T> {
  return dataset.sort((x, y) => {
    const fieldA = geUnix(getObjectProperty(x, fieldName))
    const fieldB = geUnix(getObjectProperty(y, fieldName))

    // if there is timestamp to zero, we return as DESC order.
    // we force project with no date to be at the end of the array
    if (fieldA === 0 || fieldB === 0) {
      return fieldB - fieldA
    }

    if (sortType === 'ASC') {
      return fieldA - fieldB
    }

    return fieldB - fieldA
  })
}

export default sortByDate
