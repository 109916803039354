import addStatusToProject from '@helpers/addStatusToProject'
import sortByDate, { SortType } from '@helpers/sortByDate'
import Status from '@models/projects/enums/status.enum'
import Project from '@models/projects/interfaces/project.interface'

export const defaultFilter = {
  [Status.ACTIVE]: [],
  [Status.UPCOMING]: [],
  [Status.ENDED]: []
}

/**
 * Sort project.
 *
 * A project is considering ENDED when ended is equals to true.
 * A project is considering ACTIVE when poolOpenDate is in the past. (When now > poolOpenDate)
 * A project is considering UPCOMING when poolOpenDate is undefined or poolOpenDate is in the future. Note: If poolOpenDate is undefined, TBD will be display
 * @param {array} projects
 * @returns
 */

const sortProjectsByStatus = (projects: Array<Project>) => {
  if (!projects) return defaultFilter

  const filtered = projects.reduce((state, project) => {
    const projectWithSatus = addStatusToProject(project)
    const { status } = projectWithSatus
    const sort = status === Status.ENDED ? SortType.DESC : SortType.ASC

    return {
      ...state,
      [status]: sortByDate(
        [...state[status], { ...project, status }],
        'poolOpenDate',
        sort
      )
    }
  }, defaultFilter)

  return filtered
}

export default sortProjectsByStatus
