import tw, { css } from 'twin.macro'

const multipleStyles = css`
  .nft-artwork:before {
    content: '';
    display: block;
    position: absolute;
    top: -0.1375rem;
    right: -0.1375rem;
    width: 100%;
    height: 100%;
    ${tw`border-neutral-silver`}
    ${tw`border`}
    ${tw`rounded-lg`}
    transition: border;
    ${tw`duration-base`}
  }

  &:hover {
    .nft-count {
      opacity: 1;
    }
  }
`

const styles = ({ hasMultiple }) => css`
  position: relative;

  .nft-artwork {
    ${tw`w-10`}
    ${tw`h-10`}

    position: relative;
    transition: border;
    ${tw`duration-base`}

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      ${tw`border`}
      ${tw`border-neutral-stone`}
      ${tw`rounded-md`}
    }
  }

  .nft-count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    ${tw`justify-center`}
    ${tw`items-center`}
    ${tw`rounded-full`}
    ${tw`bg-secondary`}
    ${tw`text-neutral-dark`}
    ${tw`w-6`}
    ${tw`h-6`}
    ${tw`text-caption-md`}
    opacity: 0;
    ${tw`transition-opacity`}
    ${tw`duration-base`}
  }

  &:hover {
    .nft-artwork,
    .nft-artwork {
      img {
        ${tw`border-secondary`}
      }
    }
  }

  ${hasMultiple && multipleStyles}
`

export default styles
