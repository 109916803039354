import { IconButton, Typography } from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import React, { useEffect, useState, ReactNode } from 'react'
import { VscClose } from 'react-icons/vsc'
import styles from './styles'

interface BannerProps {
  text: string
  cta?: ReactNode
  onClose?: () => void
}

const Banner: React.FC<BannerProps> = ({ text, cta, onClose }) => {
  const [visible, setVisible] = useState(false)
  const { t: tGlobal } = useTranslation('global')

  useEffect(() => {
    setTimeout(() => setVisible(true), 100)
  }, [])

  const handleClose = () => {
    setVisible(false)
    if (onClose) onClose()
  }

  return (
    <div className="transform" css={styles({ visible })}>
      <div className="banner-inner container">
        <Typography size="medium" className="text" caption>
          {text}
        </Typography>
        <div>
          {cta || (
            <IconButton
              color="secondary"
              onClick={handleClose}
              icon={<VscClose size="20" />}
              title={tGlobal('close')}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Banner
