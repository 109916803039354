import { createContext } from 'react'
import client from '@contentful/client'
import { settingsFragment } from '@contentful/settings'
import { pageIds } from '@config/contentful'
import { gql } from '@apollo/client'
import { useQuery } from 'react-query'

interface IContext {
  footerDescription: string
  mediumUrl: string
  twitterUrl: string
  youTubeUrl: string
  telegramUrl: string
  instagramUrl: string
}

const defaultSettings = {
  footerDescription: '',
  mediumUrl: '',
  twitterUrl: '',
  youTubeUrl: '',
  telegramUrl: '',
  instagramUrl: ''
}

export const SettingsContext = createContext<Partial<IContext>>(defaultSettings)

const SettingsContextProvider = ({ children }) => {
  const { data } = useQuery('settings', async () => {
    try {
      const response = await client.query({
        query: gql`
          query SettingsQuery($preview: Boolean!, $pageId: String!) {
            settings(id: $pageId, preview: $preview) {
              ...settingsFields
            }
          }
          ${settingsFragment}
        `,
        variables: {
          preview: process.env.CONTENTFUL_PREVIEW_MODE,
          pageId: pageIds.settings
        },
        fetchPolicy: 'network-only'
      })

      return {
        settings: response.data.settings
      }
    } catch (error) {
      return {
        settings: {}
      }
    }
  })

  const settings = data ? data.settings : defaultSettings

  return (
    //@ts-ignore
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsContextProvider
