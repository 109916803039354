import { Metamask, WalletConnect } from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import useWallet from '@contexts/WalletContext/hooks/useWallet'
import React, { useEffect, useState } from 'react'
import { Button, Divider, Modal, Typography } from '@jeromevvb/equinox-lib'

export interface ConnectWalletButtonProps {
  className?: string
  size?: 'small' | 'medium' | 'large'
  color?: 'primary' | 'secondary'
  variant?: 'contained' | 'outlined'
}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = ({
  className,
  size = 'medium',
  variant = 'outlined',
  color = 'primary'
}) => {
  const { t } = useTranslation('layout')
  const { connect } = useWallet()
  const [showWalletOptions, setShowWalletOptions] = useState(false)
  const [hostname, setHostname] = useState(null)

  const handleClickConnectWallet = async (
    walletName: 'metamask' | 'walletconnect'
  ) => {
    setShowWalletOptions(false)
    const useWalletConnect = walletName === 'walletconnect'
    connect(useWalletConnect)
  }

  useEffect(() => {
    const hostname = window?.location?.hostname
    setHostname(hostname)
  }, [])

  return (
    <>
      <Modal
        show={showWalletOptions}
        setShow={setShowWalletOptions}
        size="small"
      >
        <div className="grid grid-cols-1 gap-y-4">
          <div
            onClick={() => handleClickConnectWallet('metamask')}
            className="flex flex-col items-center cursor-pointer"
          >
            <Metamask className="w-12" />
            <Typography size="small">{t('wallets.metamask')}</Typography>
          </div>

          <Divider horizontal />

          <div
            onClick={() => handleClickConnectWallet('walletconnect')}
            className="flex flex-col items-center cursor-pointer"
          >
            <WalletConnect className="w-10 mb-1.5" />
            <Typography size="small">{t('wallets.walletconnect')}</Typography>
          </div>
        </div>
        <Typography caption color="muted" className="text-center mt-8">
          For your security, please make sure the URL is {hostname}
        </Typography>
      </Modal>
      <Button
        className={className}
        size={size}
        variant={variant}
        color={color}
        onClick={() => setShowWalletOptions(true)}
        data-testid="button-wallet-connect"
      >
        {t('header.connectWallet')}
      </Button>
    </>
  )
}

export default ConnectWalletButton
