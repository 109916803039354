const truncateAddress = (address: string, length = 4): string => {
  if (!address) return ''

  const truncateFirstPart = address.slice(0, length)
  const truncateSecondPart = address.slice(
    address.length - length,
    address.length
  )

  return `${truncateFirstPart}..${truncateSecondPart}`
}

export default truncateAddress
