export enum KYCStatus {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  NOT_STARTED = 'NOT_STARTED'
}

export type TKYCStatus = 'APPROVED' | 'DECLINED' | 'PENDING' | 'NOT_STARTED'

export interface TKYCContext {
  status: TKYCStatus
  isApproved: boolean
  country: string
}

export interface TKYCResponseData {
  id: string
  email: string
  firstName: string
  lastName: string
  status: TKYCStatus
  passbaseId: string
  country: string
}
