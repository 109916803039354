import { Offline } from 'react-detect-offline'
import { Typography } from '@jeromevvb/equinox-lib'
import useTranslation from '@hooks/useTranslation'
import styles from './styles'

const OfflineBanner = () => {
  const { t } = useTranslation('layout')

  return (
    <Offline>
      <div css={styles}>
        <Typography size="medium" color="dark" weight="semibold" caption>
          {t('pageError.offline')}
        </Typography>
      </div>
    </Offline>
  )
}

export default OfflineBanner
